<template>
  <div>
    <app-main>
      <template v-slot:main-content>
        <div>
          <div class="content">
            <div class="table">
              <el-table
                :data="orderData.data"
                style="width: 100%">
                <el-table-column
                  label="序号"
                  width="70">
                  <template slot-scope="scope"><span>{{scope.$index+(orderData.current_page - 1) * orderData.per_page + 1}}</span></template>
                </el-table-column>
                <el-table-column
                  prop="goods_name"
                  label="商品"
                  width="180">
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="商品主图">
                  <template slot-scope="scope">
                    <img style="height:50px" :src="scope.row.goods_images"/>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="goods_price"
                  label="商品价格">
                </el-table-column>
                <el-table-column
                  prop="package_num"
                  label="包裹数量">
                </el-table-column>
                <el-table-column
                  prop="order_price"
                  label="订单金额">
                </el-table-column>
                <el-table-column
                  prop="nickname"
                  label="用户昵称">
                </el-table-column>
                <el-table-column
                  prop="money"
                  label="网站利润">
                </el-table-column>
              </el-table>
              <div class="pagination">
                <el-pagination
                  background
                  @current-change="currentChange"
                  :page-size="orderData.per_page"
                  layout="prev, pager, next"
                  :current-page="orderData.current_page"
                  :total="orderData.total">
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </template>
    </app-main>
  </div>
</template>

<script>
import AppMain from 'components/baseStructure/AppMain'
import { getOrderList, changeOrderStatus, statusNum, getTotalList } from 'network/api'
export default {
  inject: ['mainReload'],
  components: {
    AppMain
  },
  data () {
    return {
      orderData: {},
      spanIndex: 0,
      options: ['淘宝/天猫', '阿里巴巴', '拼多多', '京东', '抖音', '其他'],
      dateSelection: ['今天', '昨天', '最近7天', '最近30天'],
      form: {
        value: '', // 平台
        times: [], // 选择时间
        msg: '', // 备注
        order: '' // 单号
      },
      platform: '', // 筛选平台参数
      activeIndex: '0', // 筛选状态参数
      currentPage: '1', // 当前页
      statusNum: {}
    }
  },
  props: ['editInfo'],
  created () {
    // 初始化时间选择器
    // const dayStar = new Date()
    // const dayEnd = new Date()
    // dayStar.setTime(dayStar.getTime())
    // dayEnd.setTime(dayEnd.getTime() + 24 * 3600 * 1000)
    // this.form.times = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
    // // 初始化订单列表
    // this.getList()
    // // 初始化子订单数量状态
    // this.getStatusNum({ createtime: this.form.times })
    getTotalList().then(res => {
      this.orderData = res.data
      console.log('统计收益', res)
    })
  },
  methods: {
    // 根据分页改变获取数据
    currentChange (val) {
      getTotalList({
        page: val,
        appkey: this.$appkey
      }).then(res => {
        this.orderData = res.data
        console.log('统计收益', res)
      })
    },
    // menu激活回调
    selectChange (index) {
      this.$emit('menuChange', index)
    },
    // 清除选择平台
    selectClear () {
      this.platform = ''
    },
    // 筛选订单列表请求
    getList () {
      getOrderList({
        appkey: this.$appkey,
        order_status: this.activeIndex, // 订单状态
        order_no: this.form.order, // 订单单号
        createtime: this.form.times, // 时间
        order_tips: this.form.msg, // 备注
        platform: this.platform, // 平台
        page: this.currentPage // 当前页
      }).then(res => {
        console.log(res)
        this.orderData = res.data
      })
    },
    // 获取不同状态子订单数量
    getStatusNum (params) {
      statusNum(params).then(res => {
        this.statusNum = res.data
      })
    },
    // menu改变
    menuChange (index) {
      this.activeIndex = index
      this.currentPage = '1'
      switch (index) {
        // 全部
        case '0':
          this.getList()
          break
        // 待支付
        case '1':
          this.getList()
          break
        // 打单中
        case '2':
          this.getList()
          break
        // 已出单
        case '3':
          this.getList()
          break
        // 异常
        case '4':
          this.getList()
          break
        // 取消
        case '5':
          this.getList()
          break
      }
    },
    // 分页改变
    curChange (val) {
      this.currentPage = val
      this.getList()
    },
    // 选择时间改变
    dateChange (val) {
      this.form.times = val
      this.spanIndex = false
      // 查询
      this.getList()
      this.getStatusNum({ createtime: this.form.times, appkey: this.$appkey })
    },
    // 日期点击
    dateClick (index) {
      this.spanIndex = index
      var dayStar = new Date()
      var dayEnd = new Date()
      switch (index) {
        // 今天
        case 0:
          dayStar.setTime(dayStar.getTime())
          dayEnd.setTime(dayEnd.getTime() + 24 * 3600 * 1000)
          this.form.times = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
          this.getList()
          this.getStatusNum({ createtime: this.form.times, appkey: this.$appkey })
          break
        // 昨天
        case 1:
          dayStar.setTime(dayStar.getTime() - 24 * 3600 * 1000)
          dayEnd.setTime(dayEnd.getTime())
          this.form.times = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
          this.getList()
          this.getStatusNum({ createtime: this.form.times, appkey: this.$appkey })
          break
        // 最近7天
        case 2:
          dayStar.setTime(dayStar.getTime() - 7 * 24 * 3600 * 1000)
          dayEnd.setTime(dayEnd.getTime() + 24 * 3600 * 1000)
          this.form.times = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
          this.getList()
          this.getStatusNum({ createtime: this.form.times, appkey: this.$appkey })
          break
        // 最近30天
        case 3:
          dayStar.setTime(dayStar.getTime() - 30 * 24 * 3600 * 1000)
          dayEnd.setTime(dayEnd.getTime() + 24 * 3600 * 1000)
          this.form.times = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
          this.getList()
          this.getStatusNum({ createtime: this.form.times, appkey: this.$appkey })
          break
      }
    },
    // 查询
    queryClick () {
      this.getList()
      this.getStatusNum({ createtime: this.form.times, appkey: this.$appkey })
    },
    // 重置
    resetClick () {
      this.mainReload()
    },
    // 取消订单
    remove (id) {
      changeOrderStatus({ id: id, order_status: '5', appkey: this.$appkey }).then(res => {
        this.getList()
        this.getStatusNum({ createtime: this.form.times, appkey: this.$appkey })
        this.$message({
          message: '成功',
          type: 'success'
        })
      })
    }
  }
}
</script>

<style scoped src="styles/components/queryHeader.css">
</style>
<style scoped src="styles/views/orderAdmin/orderTable.css">
</style>
