import { render, staticRenderFns } from "./Income.vue?vue&type=template&id=7b8c63a3&scoped=true&"
import script from "./Income.vue?vue&type=script&lang=js&"
export * from "./Income.vue?vue&type=script&lang=js&"
import style0 from "styles/components/queryHeader.css?vue&type=style&index=0&id=7b8c63a3&scoped=true&lang=css&"
import style1 from "styles/views/orderAdmin/orderTable.css?vue&type=style&index=1&id=7b8c63a3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b8c63a3",
  null
  
)

export default component.exports